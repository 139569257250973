/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-11 18:13:16
 * @Module: 筛选表单
 */
 <template>
  <el-form
    :inline="true"
    label-suffix=":"
    :model="searchForm"
    size="mini"
    style="margin-top: 20px"
  >
    <el-form-item label="关键词">
      <el-input
        v-model="searchForm.keyword"
        placeholder="关键词，可模糊搜索编号，手机，微信"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="客户类型">
      <typeform v-model="searchForm.type" clearable />
    </el-form-item>
    <el-form-item label="状态">
      <stateform v-model="searchForm.state" clearable />
    </el-form-item>
    <el-form-item label="填表人">
      <providerform v-model="searchForm.providerId" clearable />
    </el-form-item>
    <el-form-item label="转化人">
      <transformerform v-model="searchForm.transformerId" clearable :refresh="false"/>
    </el-form-item>
    <el-form-item label="组织机构">
      <org v-model="searchForm.orgId" />
    </el-form-item>
    <!-- <el-form-item label="渠道">
      <commSelect v-model="searchForm.channel" propKey="channel"/>
    </el-form-item>
    <el-form-item label="是否成交">
      <commSelect v-model="searchForm.deal"/>
    </el-form-item>
    <el-form-item label="成交产品">
      <commSelect v-model="searchForm.dealProduct" propKey="dealProduct"/>
    </el-form-item> -->
    <el-form-item>
      <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
      <el-button type="primary" size="small" icon="el-icon-download" 
      @click="exportData">导出全部</el-button>
    </el-form-item>
  </el-form>
</template>
 <script>
 import org from "@/components/form/org";
import typeform from "./form/typeform";
import transformerform from "./form/transformerform";
import stateform from "./form/stateform";
import providerform from "./form/providerform";
export default {
  components: {
    typeform,
    transformerform,
    stateform,
    providerform,
    org
  },
  props: {
    searchForm: {
      type: Object,
      default: () => ({
        type: null,
        state: null,
        providerId: null,
        transformerId: null,
        keyword: "",
        orgId:null,
        dealProduct:null,
        deal:null,
        channel:null,
      }),
    },
    showExportData:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onSubmit() {
      this.$emit("submit");
    },
    exportData(){
      this.$emit("exportData")
    }
  },
};
</script>
 <style lang='scss' scoped>
</style>