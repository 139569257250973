/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-06 12:09:56
 * @Module: 头部
 */
 <template>
  <header class="header">
    <div style="
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      ">
      <!-- <i class="el-icon-s-unfold" style="font-size:25px;color:#0062FF;cursor: pointer;" v-show="collapse" @click="()=>collapseClick(false)"></i>
      <i class="el-icon-s-fold" style="font-size:25px;color:#ccc;cursor: pointer;" v-show="!collapse" @click="()=>collapseClick(true)"></i> -->
      <div style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
        ">
        <div style="display: flex">
          <span class="title" v-if="title">{{ title }}</span>
          <div class="moving" @click="$router.back()" v-else>
            <i class="el-icon-arrow-left"></i>
            返回
          </div>
        </div>
        <div class="header-c" v-if="[2].includes(user.role)">

          <div class="headerdaojishi" v-if="online" :key="outTime">
            <p style="font-size:12px;">离线倒计时：</p>
            <el-statistic @change="change" format="mm分钟ss秒" :value="outTime" time-indices :inherit="{ color: 'inherit ' }">
            </el-statistic>
          </div>
          <el-tag type="success" size="mini" class="pointer" v-if="online" @click="putAccountOnline">在线</el-tag>
          <el-tag type="info" size="mini" class="pointer" v-else @click="putAccountOnline">离线</el-tag>
        </div>
        <div class="header-r">
          <!-- 导出列表 -->
          <export-data  />

          <div style="font-size:12px;">
            <span v-if="user.role === 0" style="color: #0ac258">管理员</span>
            <span v-else-if="user.role == 1" style="color: #262626">前端</span>
            <span v-else-if="user.role == 2" style="color: #e6a23c">后端</span>
            <span v-else-if="user.role == 3" style="color: #0D72FF">客户</span>
          </div>

          <el-dropdown @command="handleCommand">
            <div class="name pointer">{{ user.name }}</div>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="out" class="pointer">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <el-button type="danger" size="mini" icon="el-icon-ali-guanbi1" circle @click="logout"></el-button> -->
        </div>
      </div>
    </div>
  </header>
</template>
 <script>
import { mapState } from "vuex";
import exportData from "@/components/exportData.vue";
export default {
  components: { exportData },
  data () {
    return {
      // online: true
    };
  },
  mounted () {
  },
  computed: {
    ...mapState({
      user: (state) => state.userInfo.user,
      collapse: (state) => state.collapse,
      outTime: (state) => state.userInfo.outTime,
      online: (state) => state.userInfo.online
    }),
    title () {
      if (this.$route.meta.title) return this.$route.meta.title;
      else return "";
    },
  },
  methods: {
    change (value) {
      if (value > 1000) {
        (!this.online) && this.$store.commit("userInfo/setOnline", true)
      } else {
        this.online && this.$store.commit("userInfo/setOnline", false)
      }
    },
    async logout () {
      try {
        await this.$confirm("此操作将退出当前登录账号, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        this.$message({
          type: "success",
          message: "退出登录成功!",
        });
        this.$store.commit("userInfo/logout");
      } catch (error) {
        this.$message({
          type: "info",
          message: "已取消退出登录",
        });
      }
    },
    collapseClick (res) {
      this.$store.commit("collapse", res);
    },
    handleCommand (command) {
      if (command === "out") {
        this.logout();
      }
    },
    async putAccountOnline () {
      try {
        await this.$confirm(`此操作将修改状态为${this.online ? '离线' : '在线'}, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
        const { status, info } = await this.$api.putAccountOnline({ online: !this.online });
        if (status === 200) {
          this.$message({
            type: "success",
            message: info,
          });
          this.$store.commit("userInfo/creatTime")
          this.$store.commit("userInfo/setOnline", !this.online)
        } else {
          this.$message({
            type: "error",
            message: info,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },

  },
};
</script>
 <style lang='scss' scoped>
.headerdaojishi {
  display: flex;
  align-items: center;
  margin-right: 12px;
  /deep/.el-statistic {
    width: auto;
    .con {
      // color: rgb(252, 202, 0);
      .number {
        font-size: 16px !important;
      }
    }
  }
}
.header {
  background: #fff;
  height: 95%;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  padding: 0 24px;
  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #262626;
  }
	&-c{
		display: flex;
		align-items: center;
	}
  &-r {
    display: flex;
    align-items: center;
    .name {
      font-size: 13px;
      font-weight: 500;
      color: #666;
      padding: 0 20px;
    }
    .exportdata {
      font-size: 13px;
      font-weight: 500;
      color: #666;
    }
  }
}
</style>