/* 
 * @Author: 曹俊杰 
 * @Date: 2022-10-20 21:36:56
 * @Module: 平台
 */
 <template>
  <el-select :value="value" :multiple="multiple" :placeholder="placeholder" clearable @change="change" style="width:100%;">
    <el-option v-for="item in options" :key="item.orgId" :label="item.name" :value="item.orgId">
    </el-option>
  </el-select>
</template>
 <script>

export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: '选择组织机构'
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data() {
    return {
      options: []
    };
  },
  mounted() {
    this.getOrgList()
  },
  methods: {
    change(res) {
      this.$emit('input', res)
    },
    async getOrgList() {
      const { status, data } = await this.$api.getOrgList({ size: 9999 })
      if (status == 200) {
        this.options = data;
      }
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>