<template>
    <span> {{ text }} </span>
</template>

<script>
import commSelectData from './commSelectData.js'
export default {
    name: 'commRender',
    mixins: [commSelectData],
    props: {
        propKey: {
            type: String,
            default: 'boolean'
        },
        value: {
            type: [Number, Boolean, String],
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {};
    },
    mounted() { },
    computed: {
        text() {
            if (this.multiple) {
                return this.value.split(',') // 分割字符串
                    .map(val => this.list.find(item => item.value === val)?.label) // 查找对应的 label
                    .filter(Boolean) // 过滤掉未匹配的项
                    .join(','); // 组合成字符串
            }
            const found = this.list.find(res => res.value == this.value)
            if (typeof found !== 'undefined') {
                return found.label
            }
            return '--'
        }
    },
    methods: {},
};
</script>

<style scoped></style>