/* 
 * @Author: 曹俊杰 
 * @Date: 2022-08-08 13:36:54
 * @Module: 创建线索
 */
<template>
  <div class="add">
    <el-dialog :append-to-body="true" :title="type == 'revision' ? '编辑线索' : type == 'add' ? '创建线索' : ''" :visible.sync="dialogVisible" :close-on-click-modal	="false" :size="670" @closed="closed">
      <div>
        <el-form style="padding: 0 20px" size="mini" label-position="top" label-width="120px" :model="form" ref="form">
          <el-row :gutter="20" style="margin-top: 20px">
            <el-col :span="8">
              <el-form-item label="线索类型" prop="type" :rules="{ required: true, message: '请选择线索类型' }">
                <typeform v-model="form.type"></typeform>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="转化人" prop="transformerId" :rules="{ required: true, message: '请选择转化人' }">
                <transformerform v-model="form.transformerId"></transformerform>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="客户电话" prop="phone" :rules="{ required: true, message: '请输入客户电话' }">
                <el-input v-model="form.phone" placeholder="请输入客户电话"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="客户微信" prop="weixin" :rules="{ required: true, message: '请输入客户微信' }">
                <el-input v-model="form.weixin" placeholder="请输入客户微信"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="组织机构" prop="orgId">
                <org v-model="form.orgId" />
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item label="线索来源" prop="source">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 8 }" v-model="form.source" placeholder="请输入线索来源"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="线索来源" prop="source">
                <el-select v-model="form.source" placeholder="请选择线索来源" style="width: 100%;">
                  <el-option label="葱哥抖音私信" value="葱哥抖音私信"> </el-option>
                  <el-option label="葱哥抖音电话" value="葱哥抖音电话"> </el-option>
                  <el-option label="葱哥抖音视频" value="葱哥抖音视频"> </el-option>
                  <el-option label="葱哥账号直播间" value="葱哥账号直播间"> </el-option>
                  <el-option label="律烁丁律" value="律烁丁律"> </el-option>
                  <el-option label="律烁王律" value="律烁王律"> </el-option>
                  <el-option label="律烁徐律" value="律烁徐律"> </el-option>
                  <el-option label="张宏涛律师" value="张宏涛律师"> </el-option>
                  <el-option label="樊律" value="樊律"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="渠道" prop="channel" >
                <commSelect v-model="form.channel" propKey="channel"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否成交" prop="deal" >
                <commSelect v-model="form.deal"/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="成交时间" prop="dealDate" >
                <el-date-picker value-format="timestamp" style="width:100%;" v-model="form.dealDate" type="date" placeholder="选择完成时间" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="成交金额" prop="dealAmount" >
                <el-input type="number" v-model="form.dealAmount" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="成交产品" prop="dealProduct" >
                <commSelect v-model="form.dealProduct" propKey="dealProduct" multiple/>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="备注" prop="note">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 12 }" v-model="form.note" placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="客资详情" prop="detail">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 12 }" v-model="form.detail" placeholder="请输入客资详情"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="聊天截图" prop="images" :rules="{ required: true, message: '请上传聊天截图' }">
                <uploadImage v-model="form.images" :limit="30" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div style="
            height: 80px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0 24px;
          ">
          <el-button type="primary" size="small" @click="submit" style="float: right">提交</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import org from "@/components/form/org";
import typeform from "./form/typeform";
import transformerform from "./form/transformerform";
import uploadImage from "@/components/uploadImage/uploadImage";
export default {
  components: {
    typeform,
    uploadImage,
    transformerform,
    org
  },
  data() {
    const phoneValidator = (_, value, callback) => {
      if (this.$utils.test.mobile(value)) {
        return callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
    };
    return {
      dialogVisible: false,
      form: {
        type: null,
        phone: null,
        weixin: null,
        source: null,
        detail: null,
        customerId: null,
        transformerId: null, //转化人iD
        orgId: null,

        channel:null,
        deal:null,
        dealDate:null,
        dealAmount:null,
        dealProduct:null,
        note:null,
      },
      type: "add",
    };
  },
  mounted() { },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.putCompany();
        } else {
          return false;
        }
      });
    },
    show({ type = "add", data }) {
      if (type == "revision") {
        this.form = this.$utils.deepClone(data);
        console.log(data);
      }
      this.type = type;
      this.dialogVisible = true;
    },

    async putCompany() {
      // let { related } = this.form
      let api = {
        revision: {
          func: this.$api.putCustomer,
          params: {
            ...this.form,
            customerId: this.$route.params.customerId || this.form.customerId,
            // related: related.toString()
          },
        },
        add: {
          func: this.$api.postCustomer,
          params: {
            ...this.form,
            // related: related.toString()
          },
        },
      };
      delete api.add.params.customerId;
      const { status, data, info } = await api[this.type].func(
        api[this.type].params
      );
      if (status === 200) {
        this.$message({
          type: "success",
          message: info,
        });
        this.dialogVisible = false;
        this.$emit("success");
      } else {
        this.$message({
          type: "error",
          message: info,
        });
      }
    },

    closed() {
      this.form = {
        type: null,
        phone: null,
        weixin: null,
        source: null,
        detail: null,
        customerId: null,
      };
    },
  },
};
</script>
<style lang='scss' scoped>
</style>
