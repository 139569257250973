/* 
 * @Author: 曹俊杰 
 * @Date: 2022-09-13 15:49:50
 * @Module: 线索类型
 */
 <template>
  <div>
    <el-select :value="value" placeholder="请选择线索类型" clearable @change="change" style="width:100%">
      <el-option v-for="item in [{
				value:0,
				label:'主播'
			},
			{
				value:1,
				label:'公会'
			},{
				value:3,
				label:'打赏纠纷'
			},
			{
				value:2,
				label:'其它'
			}]" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
 <script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    change (value) {
      this.$emit('input', value)
    }
  },
};
 </script>
 <style lang='scss' scoped>
</style>