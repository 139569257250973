<template>
    <el-select :value="renderValue" placeholder="请选择" @change="change" style="width: 100%;" clearable :multiple="multiple">
        <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value" >
        </el-option>
    </el-select>
</template>

<script>
import commSelectData from './commSelectData.js'
export default {
    props: {
        propKey: {
            type: String,
            default: 'boolean'
        },
        value: {
            type: [Number, Boolean,String],
            default: null
        },
        multiple:{
            type:Boolean,
            default:false
        }
    },
    mixins: [commSelectData],
    data() {
        return {
           
        }
    },
    computed:{
        renderValue(){
            if(this.multiple && this.value){
                return this.value.split(',')
            }
            return this.value
        }
    },
    methods: {
        change(res) {
            if(res===''){
                this.$emit('input',null)
                return
            }
            if(this.multiple){
                this.$emit('input',res.join(',')||null)
                return 
            }
            this.$emit('input', res)
        },
    }
}
</script>