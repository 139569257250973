export default {
    data() {
        return {
            booleanList: [{
                value: true,
                label: '是'
            }, {
                value: false,
                label: '否'
            }],
            channelList: [{
                value: 1,
                label: '小红书'
            }, {
                value: 2,
                label: '抖音'
            }, {
                value: 3,
                label: '视频号'
            }, {
                value: 4,
                label: '其他'
            }],
            dealProductList: [{
                value: '1',
                label: '咨询费'
            }, {
                value: '2',
                label: '发函'
            }, {
                value: '3',
                label: '协商'
            }, {
                value: '4',
                label: '个案'
            }]
        }
    },
    computed: {
        list() {
            if (this.propKey === 'channel') {
                return this.channelList
            }
            if (this.propKey === 'dealProduct') {
                return this.dealProductList
            }
            return this.booleanList
        },

    },
}