var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"value":_vm.value,"placeholder":"请选择线索类型","clearable":""},on:{"change":_vm.change}},_vm._l(([{
				value:0,
				label:'主播'
			},
			{
				value:1,
				label:'公会'
			},{
				value:3,
				label:'打赏纠纷'
			},
			{
				value:2,
				label:'其它'
			}]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }